import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import SidebarLayout from './Components/SidebarLayout/Index';
import {
  ChartPieIcon,
  CircleStackIcon,
  HomeIcon,
  CloudIcon,
  CalculatorIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline'
import CollectorPage from './Pages/CollectorPage/Index';
import PlatformsPage from './Pages/PlatformsPage/Index';
import Login from './Pages/Auth/Login';
import Platform from './Pages/Platform/Index';
import CampaignsPage from './Pages/CampaignsPage/Index';
import CampaignInfo from './Pages/Campaign/CampaignInfo';
import Campaign from './Pages/Campaign/Index';
import CollectorStatsPage from './Pages/CollectorStatsPage/Index';


const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
  { name: 'Platforms', href: '/platforms', icon: CloudIcon, current: false },
  { name: "Collectors", href: "/collectors", icon: CircleStackIcon, current: false },
  { name: 'Agents', href: '/agents', icon: CalculatorIcon, current: false },
  { name: 'Reports', href: '/reports', icon: ChartPieIcon, current: false},
  { name: 'Campaigns', href: '/campaigns', icon: ListBulletIcon, current: false}
];

const Router = () => {



  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<SidebarLayout navigation={navigation} />}>
          <Route path="" element={<p>Home</p>} />
          <Route path="team">
            <Route index={true} element={<p>Team</p>} />
          </Route>
          <Route path="collectors">
            <Route index={true} element={<CollectorPage />} />
            <Route path=":id" element={<p>Collector</p>} />
            <Route path=":id/stats" element={<CollectorStatsPage/>} />
          </Route>
          <Route path="reports">
            <Route index={true} element={<p>Reports</p>} />
          </Route>
          <Route path="platforms">
            <Route index={true} element={<PlatformsPage />} />
            <Route path=":name" element={<Platform />} />
          </Route>
          <Route path="campaigns">
            <Route index={true} element={<CampaignsPage />} />
            <Route path=":platform/:id" element={<Campaign />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default Router;