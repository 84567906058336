import axios from "axios";

const useAuth = () => {
  const apiLogin = (email, password) => {
    return axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCsrfToken = () => {
    return axios
      .get(
        `${process.env.REACT_APP_EMPOLO_URL}/sanctum/csrf-cookie`,

        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response.success;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { apiLogin, getCsrfToken };
};

export default useAuth;
