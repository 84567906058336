import React, { useContext } from "react";
import AuthLayout from "./AuthLayout";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../../Api/useAuth";
// import AnimatedWave from "../../Components/Animations/AnimatedWave";
import useUser from "../../Api/useUser";

const Login = () => {
  const { dispatchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { apiLogin } = useAuth();
  const { getUser } = useUser();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = () => apiLogin(email, password).then((response) => {
    if (response?.access_token) {
      getUser(response.access_token).then((user) => {
        dispatchAuth({
          type: "LOGIN",
          payload: response.access_token,
          user: user,
        });
        navigate("/");
      });
    } else {
      console.log("Login Failed");
      console.log(response);
    }
  });

  return (
    <AuthLayout>
      <div className="mt-10">
        <h4 className="mb-12">Welcome to the future of HR!</h4>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            e-Mail
          </label>
          <input className="border border-gray-200 rounded p-2" type="text" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input className="border border-gray-200 rounded p-2" type="password" onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button
          className="border border-gray-200 rounded p-2"
          onClick={() => login()}
        >
          Login
        </button>
      </div>
    </AuthLayout>
  );
};

export default Login;
