import React from 'react';
import { useParams } from 'react-router-dom';
import useCollector from '../../Api/useCollector';
import CollectorStats from './CollectorStats';
import CollectorStatsWeekly from './CollectorStatsWeekly';

const CollectorStatsPage = () => {
  // get name from react router params
  let { id } = useParams();
  const useCollectors = useCollector();
  const token = localStorage.getItem("token");
  const [collectorStats, setCollectorStats] = React.useState(undefined);
  const [weeklyCollectorStats, setWeeklyCollectorStats] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    console.log(id);
    async function getCollector() {
      setIsLoading(true);
      const cs = await useCollectors.getCollectorStats(id, token);
      const wcs = await useCollectors.getCollectorStats(id, token, 'weekly');
      console.log(cs);
      setCollectorStats(cs[0]);
      setWeeklyCollectorStats(wcs);
      setIsLoading(false);
    }
    getCollector();
  }, []);

  return (
    <>
      <CollectorStats collectorStats={collectorStats} isLoading={isLoading} />
      <CollectorStatsWeekly weeklyCollectorStats={weeklyCollectorStats} isLoading={isLoading} />
    </>
  );
}

export default CollectorStatsPage;