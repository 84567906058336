import React from 'react';
import useCampaigns from '../../Api/useCampaigns';

const CampaignsPage = () => {

  const uc = useCampaigns();
  const [campaigns, setCampaigns] = React.useState([]);
  const token = localStorage.getItem('token');

  React.useEffect(() => {
    async function getCampaigns() {
      const campaigns = await uc.getCampaigns(token);
      console.log(campaigns);
      setCampaigns(campaigns);
    }
    getCampaigns();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-700 my-6">Campaigns</h1>
      <div className="flex flex-wrap">
        {
          // a list view of all campaigns
          /*
            A campaign looks like this:
            {
	"0": {
		"id": 759,
		"created_at": "2023-08-24T17:53:31.741215+00:00",
		"advertiser": "640630",
		"channelKind": "UNKNOWN",
		"dailyBudget": 2682,
		"campaignId": "1244103",
		"kind": "Campaign",
		"lastUpdate": 1692899582000,
		"name": "Kampagne 4 Küchen nach Maß",
		"totalBudget": 45600,
		"adSets": null,
		"userId": null,
		"report_id": 756,
		"status": "RUNNING",
		"platform": "activeagent",
		"settings": [
			{
				"kind": 0,
				"type": "TotalBudget",
				"currency": "EUR",
				"capability": {
					"kind": 2,
					"name": "TotalBudget",
					"parentKind": "Campaign",
					"description": "The total budget of the parent resource"
				},
				"totalBudget": 45600
			},
			{
				"kind": 0,
				"type": "DailyBudget",
				"currency": "EUR",
				"capability": {
					"kind": 2,
					"name": "DailyBudget",
					"parentKind": "Campaign",
					"description": "The daily budget of the parent resource"
				},
				"dailyBudget": 2682
			}
		],
		"collector_id": 1
	}
}
          */
          campaigns.map((campaign) => (
            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2">
              <div className="bg-white rounded-lg shadow-lg">
                <div className="p-4">
                  <h5 className="text-gray-900 font-bold text-xl tracking-tight mb-2">{campaign.name}</h5>
                  <p className="text-gray-700 text-base">{campaign.advertiser}</p>
                </div>
                <div className="flex items-center justify-between px-4 py-3 bg-gray-100 rounded-b-lg">
                  <a href={`/campaigns/${campaign.platform}/${campaign.campaignId}`} className="text-blue-600 font-bold hover:underline">View Campaign</a>
                  <span className="text-gray-600 text-sm">{campaign.platform}</span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default CampaignsPage;