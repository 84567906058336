import axios from 'axios';

const useCollector = () => {

  const getCollectors = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/collectors`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getCollector = (id, token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/collectors/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  const getCollectorStats = (id, token, aggregate) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/collectors/${id}/stats?aggregate=${aggregate}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  return {
    getCollectors,
    getCollector,
    getCollectorStats
  };
}

export default useCollector;