import axios from 'axios';

const useCampaigns = () => {

  const getCampaigns = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaigns`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getCampaign = (platform, id, token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaigns/` + platform + '/' + id + '/',
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getCampaignReports = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaigns/reports`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };


  // const getCollector = (id, token) => {
  //   return axios.get(`http://localhost:3000/collectors/${id}`,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     }
  //   })
  //   .then(response => response.data)
  //   .catch(error => console.log(error));
  // }

  return {
    getCampaigns: getCampaigns,
    getCampaignReports: getCampaignReports,
    getCampaign: getCampaign
  };
}

export default useCampaigns;