import logo from './logo.svg';
import './App.css';
import Router from './Router';
import React, { useReducer, useEffect } from "react";
import authReducer from './Pages/Auth/AuthReducer';
import AuthContext from './Pages/Auth/AuthContext';

function App() {
  const [auth, dispatchAuth] = useReducer(authReducer, {
    isAuthenticated: false,
    token: null,
  });

  useEffect(() => {
    dispatchAuth({ type: "AUTH" });
  }, []);

  return (
    <AuthContext.Provider value={{ auth, dispatchAuth }}>
      <Router />
    </AuthContext.Provider>
  );
}

export default App;
