import React from 'react';
import usePlatforms from '../../Api/usePlatforms';
import PlatformsGridList from '../../Components/PlatformsGridList/Index';

const PlatformsPage = () => {

  const platform = usePlatforms();
  const [platforms, setPlatforms] = React.useState([]);
  const token = localStorage.getItem('token');

  React.useEffect(() => {
    async function getPlatforms() {
      const pfs = await platform.getPlatforms(token);
      console.log(pfs);
      setPlatforms(pfs);
    }
    getPlatforms();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-700 my-6">Platforms</h1>
      <PlatformsGridList items={platforms} baseUrl={'/platforms'} />
    </div>
  );
}

export default PlatformsPage;