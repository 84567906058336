import React, { useEffect } from 'react';

const CollectorStatsWeekly = ({ weeklyCollectorStats, isLoading }) => {

  useEffect(() => {
    console.log(weeklyCollectorStats);
  }, []);

  return (
    <>
      {
        !isLoading &&
        weeklyCollectorStats ? (
          weeklyCollectorStats.map(wcs => (  
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="my-12">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">Collector #{wcs.collector_id} - Week {wcs.week}</h1>
                <p className="text-gray-600 text-lg">Collected {wcs.unique_campaigns} unique campaigns between {new Date(wcs.earliest_date).toUTCString()} and {new Date(wcs.latest_date).toUTCString()}</p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              </div>
            </div>
          ))
        )
        : (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900 my-6">Loading...</h1>
          </div>
        )
      }
    </>
  );
}

export default CollectorStatsWeekly;
