import axios from 'axios';

const usePlatforms = () => {

  const getPlatforms = (token) => {
    return axios.get(`${process.env.REACT_APP_N2_API_URL}/platform`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getPlatform = (name, token) => {
    return axios.get(`${process.env.REACT_APP_N2_API_URL}/platform/${name}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  return {
    getPlatforms,
    getPlatform
  };
}

export default usePlatforms;