import React from 'react';
import useCollector from '../../Api/useCollector';
import CollectorsGridList from '../../Components/CollectorsGridList/Index';

const CollectorPage = () => {

  const collector = useCollector();
  const [collectors, setCollectors] = React.useState([]);
  const token = localStorage.getItem('token');

  React.useEffect(() => {
    async function getCollectors() {
      const collectors = await collector.getCollectors(token);
      console.log(collectors);
      setCollectors(collectors);
    }
    getCollectors();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-700 my-6">Collectors</h1>
      <CollectorsGridList items={collectors} baseUrl={'/collectors'} />
    </div>
  );
}

export default CollectorPage;