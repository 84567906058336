import React, { useEffect } from 'react';

const PlatformInfo = ({ platform, isLoading }) => {

  useEffect(() => {
    console.log(platform);
  }, []);

  return (
    <>
      {
        !isLoading &&
        platform ? (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="my-12">
              <h1 className="text-3xl font-bold text-gray-900 mb-6">{platform.name}</h1>
              <p className="text-gray-600 text-lg">{platform.description}</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Capabilities</h2>
                <ul>
                  {
                    platform.capabilities.map((capability, index) => (
                      <li key={index} className="mb-4">
                        <span className="inline-block bg-blue-200 text-blue-800 text-sm px-2.5 py-0.5 rounded-full">{capability.name}</span>
                        <p className="text-gray-600 mt-2">{capability.description}</p>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Channel Kinds</h2>
                <ul>
                  {
                    platform.channelKindCapabilities.map((channelKind, index) => (
                      <li key={index} className="mb-4">
                        <span className="inline-block bg-green-200 text-green-800 text-sm px-2.5 py-0.5 rounded-full">{channelKind.name}</span>
                        <p className="text-gray-600 mt-2">{channelKind.description}</p>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        )
        : (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900 my-6">Loading...</h1>
          </div>
        )
      }
    </>
  );
}

export default PlatformInfo;
