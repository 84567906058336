import React, { useEffect } from 'react';

const CampaignInfo = ({ campaign, isLoading }) => {

  useEffect(() => {
    console.log(campaign);
  }, []);

  return (
    <>
      {
        !isLoading &&
        campaign ? (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="my-12">
              <h1 className="text-3xl font-bold text-gray-900 mb-6">{campaign.name}</h1>
              <p className="text-gray-600 text-lg">{campaign.platform}</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Settings</h2>
                <ul>
                  {
                    campaign.settings.map((setting, index) => (
                      <li key={index} className="mb-4">
                        <span className="inline-block bg-blue-200 text-blue-800 text-sm px-2.5 py-0.5 rounded-full">{setting.type}</span>
                        <p className="text-gray-600 mt-2">{setting.capability.description}</p>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        )
        : (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900 my-6">Loading...</h1>
          </div>
        )
      }
    </>
  );
}

export default CampaignInfo;
