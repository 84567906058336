import React from 'react';
import PlatformsGridList from '../../Components/PlatformsGridList/Index';
import { useParams } from 'react-router-dom';
import CampaignInfo from './CampaignInfo';
import useCampaigns from '../../Api/useCampaigns';

const Campaign = () => {
  // get name from react router params
  let { platform, id } = useParams();
  const uc = useCampaigns();
  const token = localStorage.getItem("token");
  const [campaign, setCampaign] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    console.log(id);
    async function getPlatform() {
      setIsLoading(true);
      const c = await uc.getCampaign(platform, id, token);
      console.log(c);
      setCampaign(c);
      setIsLoading(false);
    }
    getPlatform();
  }, []);

  return (
    <CampaignInfo campaign={campaign} isLoading={isLoading} />
  );
}

export default Campaign;