import { PencilIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import cronstrue from 'cronstrue';

// const items = [
//   {
//     active: true,
//     created_at: "2023-04-18T14:31:06.638407+00:00",
//     cronPattern: "* * * * *",
//     id: 6,
//     name: "Test Collector mit Queue",
//     platform: "Testplatform"
//   }
// ]

  


export default function CollectorsGridList({items, baseUrl}) {

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {items && items.length > 0 ? items.map((item) => (
        <li key={item.email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">{item.platform}</h3>
                <span className={`inline-flex flex-shrink-0 items-center rounded-full ${ item.active ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"} px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ring-green-600/20`}>
                  {item.active ? 'Active' : 'Inactive'}
                </span>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{item.name}</p>
            </div>
            <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={`https://api.dicebear.com/6.x/initials/svg?seed=${item.platform}`} alt="" />
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a
                  href={`${baseUrl}/${item.id}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Edit
                </a>
              </div>
              <div className="flex w-0 flex-1">
                <div
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  {
                    cronstrue.toString(item.cronPattern)
                  }
                </div>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                  href={`${baseUrl}/${item.id}/stats`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Info
                </a>
              </div>
              
            </div>
          </div>
        </li>
      ))
      :
      // display an empty list placeholder
      <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white">
        <div className="flex w-full items-center bg-gray-50 justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="truncate text-sm font-medium text-gray-900">No Collectors found</h3>
            </div>
            <p className="mt-1 truncate text-sm text-gray-500">Please add a Collector</p>
          </div>
        </div>
      </li>
    }
    </ul>
  )
}
